import { React, useState, useCallback } from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import * as axiosBase from 'axios';
import styles from 'screens/UserTaskList/UserTaskList.module.css';
import DisplayBreadcrumbs from 'components/DisplayBreadcrumbs/DisplayBreadcrumbs';
import TaskIcon from 'components/TaskIcon/TaskIcon';
import { TaskDetailsPopup } from 'components/TaskDetailsPopup/TaskDetailsPopup';
import { useRef } from 'react';
import { changeEstimateUtil } from 'utils/changeEstimation';

const UserTasks = ({
	isWorkLoad = true,
	tasks,
	activeUser,
	totalEstimate,
	hideChilds,
	setHideChilds,
	worksheets,
	handleOpenTaskDetailsModal,
	selectedTask,
	handleCloseTaskDetailsModal,
	openTaskDetailsModal,
	setTasks,
	setSelectedTask,
	setTotalEstimate
}) => {
	const [headerVisible, setHeaderVisible] = useState('0px');
	const updates = useRef([]);
	const timeoutId = useRef([]);
	const accessToken = localStorage.getItem('access_token');

	const axios = axiosBase.create({
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${accessToken}`,
			'Access-Control-Allow-Origin': '*'
		}
	});

	function useDebouncedCallback(callback, delay, updates, timeoutId) {
		// Define a memoized callback function
		return useCallback(
			newItem => {
				// Find the index of the new item in the updates array
				const index = updates.current.findIndex(item => item._id === newItem._id);

				// If the new item is not found, add it to the updates array
				if (index === -1) {
					updates.current = [...updates.current, newItem];
				} else {
					// If the new item is found, replace it in the updates array
					updates.current[index] = newItem;
				}

				// Clear any existing timeout for the current item
				if (timeoutId.current[newItem._id]) {
					clearTimeout(timeoutId.current[newItem._id]);
				}

				// Set a new timeout for the current item
				timeoutId.current[newItem._id] = setTimeout(() => {
					// Call the provided callback with the new item after the delay
					callback(newItem);

					// Remove the item from the updates array
					updates.current = updates.current.filter(item => item._id !== newItem._id);
				}, delay);
			},
			[callback, delay, updates, timeoutId]
		);
	}

	const updateTaskOnBackend = useDebouncedCallback(
		newItem => {
			updateTaskApi(newItem);
		},
		1000,
		updates,
		timeoutId
	);

	const updateTaskApi = newItem => {
		let item = [...tasks].find(r => r._id === newItem._id);
		toastr.clear();
		const body = {
			...newItem,
			hide_children: newItem.hide_children ? newItem.hide_children : false
		};
		axios
			.put(`${process.env.REACT_APP_LARAVEL_API_URL}/task/${newItem.todo_id}`, body, {
				// headers: {
				// 	Authorization: `Bearer ${token}`,
				// 	'Access-Control-Allow-Origin': '*'
				// }
			})
			.then(res => {
				if (res.data.success === true) {
					if (item.description !== newItem.description) {
						setTimeout(() => toastr.success(`Description updated successfully. `), 300);
					}
				} else {
					setTimeout(
						() =>
							toastr.error(
								`Something went wrong while updating description. Please try again later`
							),
						300
					);
				}
			})
			.catch(error => {
				console.error(error);
				// setTimeout(
				// 	() =>
				// 		toastr.error(
				// 			`Something went wrong while updating description. Please try again later`
				// 		),
				// 	300
				// );
			});
	};

	const updateBudget = (newItem, taskListCopy) => {
		var todo = newItem;

		while (todo.parent_id !== '') {
			//not a first level task

			var immediateParent = taskListCopy.filter(function (value) {
				return todo.parent_id === value._id;
			})[0];

			var immediateIncompleteChildren = taskListCopy.filter(function (value) {
				return immediateParent._id === value.parent_id && !value.completed;
			});

			if (immediateIncompleteChildren.length > 0) {
				immediateParent.estimate = immediateIncompleteChildren
					.map(value => value.estimate)
					.reduce((prev, next) => prev + next);
			} else {
				immediateParent.estimate = 0;
			}

			//for backend payload structure compatibility
			immediateParent.value = immediateParent.title;
			immediateParent.todo_id = immediateParent._id;

			var index = taskListCopy.findIndex(function (value) {
				return value._id === immediateParent._id;
			});
			taskListCopy[index] = immediateParent;

			updateTaskOnBackend(immediateParent);

			todo = immediateParent;
		}

		setTasks(taskListCopy);
		// setTaskList(taskListCopy);
		// updateSideBar(taskListCopy);
	};

	const changeEstimate = (item, direction, isTaskDetailsPopup = false, estimates = 0) => {
		changeEstimateUtil(
			item,
			direction,
			isTaskDetailsPopup,
			estimates,
			tasks,
			setTasks,
			selectedTask,
			updateTaskOnBackend,
			updateBudget,
			setSelectedTask,
			null,
			null,
			totalEstimate,
			setTotalEstimate
		);
	};

	return (
		<>
			{isWorkLoad && (
				<h1 className={`page-title`}>
					{totalEstimate} hours of Work done {activeUser?.name && `on ${activeUser.name}'s plate`}
				</h1>
			)}

			{!isWorkLoad && <h1 className={`page-title`}>Unestimated Task of {activeUser?.name}</h1>}

			<div style={{ zIndex: '10', width: '99%' }} className={styles.trans}>
				{tasks.length > 0 &&
					tasks
						.filter(function (item) {
							return item.parent_id === 0 || item.parent_id === '' || item.parent_id === null;
						})
						.sort((a, b) => a.seq - b.seq)
						.map((item, index) => {
							return (
								<ListItem
									key={index}
									item={item}
									index={index}
									tasks={tasks}
									hideChilds={hideChilds}
									setHideChilds={setHideChilds}
									handleOpenTaskDetailsModal={handleOpenTaskDetailsModal}
									activeUser={activeUser}
									worksheets={worksheets}
								/>
							);
						})}
			</div>
			<TaskDetailsPopup
				openedTask={selectedTask}
				item_id={selectedTask?._id}
				open={openTaskDetailsModal}
				handleClose={handleCloseTaskDetailsModal}
				headerVisible={headerVisible}
				changeEstimate={changeEstimate}
			/>
		</>
	);
};

export default UserTasks;

const ListItem = ({
	index,
	item,
	tasks,
	hideChilds,
	setHideChilds,
	handleOpenTaskDetailsModal,
	activeUser,
	worksheets,
	depth = 0
}) => {
	const filteredTodo = tasks
		.filter(function (childItem) {
			return childItem.parent_id === item._id;
		})
		.sort((a, b) => a.seq - b.seq);
	const parent = [...tasks].filter(function (value) {
		return value._id !== item.parent_id;
	})[0];

	return (
		<div className={styles.rowContainer}>
			{activeUser._id !== item.assginee && (
				<div>
					{filteredTodo.length > 0 && filteredTodo[0].assginee === activeUser._id && (
						<DisplayBreadcrumbs item={item} tasks={tasks} worksheets={worksheets} />
					)}
					<DisplayBreadcrumbs item={item} tasks={tasks} worksheets={worksheets} />
					{!hideChilds.includes(item._id) &&
						filteredTodo.map((childItem, childIndex) => {
							var childPrevItem = tasks.filter(function (childItem) {
								return childItem.parent_id === item._id;
							})[childIndex === 0 ? 0 : childIndex - 1];
							return (
								<ListItem
									key={'child-' + childIndex}
									item={childItem}
									index={childIndex}
									tasks={tasks}
									depth={depth + 1}
									hideChilds={hideChilds}
									setHideChilds={setHideChilds}
									handleOpenTaskDetailsModal={handleOpenTaskDetailsModal}
									activeUser={activeUser}
									worksheets={worksheets}
								/>
							);
						})}
				</div>
			)}
			{(item.parent_id === 0 || item.parent_id === '' || item.parent_id === null) &&
				activeUser._id === item.assginee && (
					<div>
						<div>
							<DisplayBreadcrumbs item={item} tasks={tasks} worksheets={worksheets} />
						</div>
					</div>
				)}
			{activeUser._id === item.assginee && (
				<div
					className='d-flex'
					style={{
						// width: '100%',
						marginLeft:
							item.parent_id === 0 || item.parent_id === '' || item.parent_id === null
								? '0px'
								: '15px'
					}}
				>
					{filteredTodo.length > 0 && hideChilds.includes(item._id) && (
						<div
							className='d-flex justify-content-center align-items-center'
							style={{ width: '20px', cursor: 'pointer', marginLeft: '-20px', marginBottom: '5px' }}
						>
							<AiOutlineRight
								onClick={e => {
									setHideChilds(prev => {
										return prev.filter(function (value) {
											return value !== item._id;
										});
									});
								}}
							/>
						</div>
					)}

					{/* checkbox */}
					<div style={{ width: '20px' }}>
						<div className='d-flex justify-content-center'>
							<TaskIcon item={item} />
						</div>
						{filteredTodo.length > 0 && !hideChilds.includes(item._id) && (
							<div
								className={`d-flex justify-content-center align-items-center ${styles.verticalLinesContainer}`}
								onClick={e => {
									setHideChilds(prev => [...prev, item._id]);
								}}
							>
								{filteredTodo.length > 0 && !hideChilds.includes(item._id) && (
									<div className={styles.verticalLines}></div>
								)}
							</div>
						)}
					</div>

					<div style={{ width: 'calc(100% - 30px)' }}>
						<div
							style={{
								display: 'flex',
								alignItems: 'top',
								gap: '10px',
								marginLeft: `10px`,
								marginBottom: '5px'
							}}
						>
							{/* estimate */}
							{
								<div className='d-flex align-items-start' style={{ marginTop: '1px' }}>
									{item.estimate !== 0 ? (
										<div
											className='d-flex align-items-end'
											style={{
												borderRadius: '12px',
												color: `${
													item.estimate <= 2 || filteredTodo.length > 0
														? '#5FB924'
														: item.estimate > 2 && item.estimate < 5
														? '#FCBA03'
														: '#FF0000'
												}`,
												fontSize: '12px',
												lineHeight: '13px',
												padding: '1px 0',
												fontFamily: 'Roboto Mono',
												fontStyle: 'normal'
											}}
										>
											<p className='m-0 p-0'>{item.estimate.toFixed(2)}</p>
										</div>
									) : item.estimate !== 0 && item.completed ? (
										<div
											className='d-flex align-items-end'
											style={{
												fontSize: '12px',
												lineHeight: '13px',
												padding: '1px 0',
												color: '#5FB924',
												fontFamily: 'Roboto Mono',
												fontStyle: 'normal'
											}}
										>
											<p className='m-0 p-0'>{item.estimate.toFixed(2)}</p>
										</div>
									) : (
										<div
											className='d-flex align-items-end'
											style={{
												// textAlign: 'center',
												borderRadius: '12px',
												fontSize: '12px',
												lineHeight: '13px',
												padding: '1px 0',
												opacity: '60%',
												color: 'white',
												fontFamily: 'Roboto Mono',
												fontStyle: 'normal'
											}}
										>
											<p className='m-0 p-0'>0.00</p>
										</div>
									)}
								</div>
							}

							{/* input value */}
							<div style={{ position: 'relative', width: '100%' }}>
								<Link
									style={{
										background: 'transparent',
										color: 'white',
										border: 0,
										fontFamily: 'Poppins',
										fontSize: '13px',
										// width: '100%',
										overflowWrap: 'anywhere',
										cursor: 'pointer',
										opacity:
											item.estimate === 0 &&
											(filteredTodo.length === 0 ||
												filteredTodo
													.map(value => value.estimate)
													.reduce((prev, next) => prev + next) === 0)
												? '60%'
												: '100%'
									}}
									to={`/chopping-interface/${item.worksheet_id}?item_id=${item._id}`}
									onClick={e => {
										e.preventDefault();
										handleOpenTaskDetailsModal(item);
									}}
								>
									{item.title !== '' ? item.title : ''}
								</Link>
							</div>
						</div>

						{!hideChilds.includes(item._id) &&
							filteredTodo.map((childItem, childIndex) => {
								var childPrevItem = tasks.filter(function (childItem) {
									return childItem.parent_id === item._id;
								})[childIndex === 0 ? 0 : childIndex - 1];
								return (
									<ListItem
										key={'child-' + childIndex}
										item={childItem}
										index={childIndex}
										tasks={tasks}
										depth={depth + 1}
										hideChilds={hideChilds}
										setHideChilds={setHideChilds}
										handleOpenTaskDetailsModal={handleOpenTaskDetailsModal}
										activeUser={activeUser}
										worksheets={worksheets}
									/>
								);
							})}
					</div>
				</div>
			)}
		</div>
	);
};
