import React, { useEffect, useState, useRef } from 'react';
import * as axiosBase from 'axios';
import { Index } from 'flexsearch';
import { BsListTask } from 'react-icons/bs';
import { FaTimes } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import {
	Box,
	Modal,
	Switch,
	Backdrop,
	CircularProgress,
	Button,
	LinearProgress,
	Typography
} from '@material-ui/core';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import Avatar from '@mui/material/Avatar';

import './globalSearch.css';
import TaskIcon from 'components/TaskIcon/TaskIcon';
import TaskDetails from '../ChoppingInterface/TaskDetails';
// import { setSearchIndex } from '../../reducers/searchIndex';
import { getSearchData, setValueToIndexedDB } from './indexedDB';
import { CloseOutlined, ContactSupportOutlined } from '@material-ui/icons';
import { TaskDetailsPopup } from 'components/TaskDetailsPopup/TaskDetailsPopup';
import { cacheTaskInRecentlyVisitedList } from 'utils/cacheUtils';
import { isMobile } from 'react-device-detect';
import { CloseOutlineNew, CommentNew, ListNew } from 'assets/icons';
import { IoMdRefresh } from 'react-icons/io';

const accessToken = localStorage.getItem('access_token');
const axios = axiosBase.create({
	headers: {
		Accept: 'application/json',
		Authorization: `Bearer ${accessToken}`,
		'Access-Control-Allow-Origin': '*'
	}
});

export const GlobalSearch = ({
	open,
	setOpenSpotlightModal,
	origin,
	activeWorksheetId,
	handleWorksheetChange
}) => {
	const [tasks, setTasks] = useState([]);
	const [notifications, setNotifications] = useState([]);
	const [comments, setComments] = useState([]);
	const [worksheets, setWorksheets] = useState([]);
	const [documents, setDocuments] = useState([]);
	const [matchedDocuments, setMatchedDocuments] = useState([]);
	const [matchedTasks, setMatchedTasks] = useState([[]]);
	const [index, setIndex] = useState(null);
	const [query, setQuery] = useState('');
	const [selectedResult, setSelectedResult] = useState(0);
	const [totalWorksheets, setTotalWorksheets] = useState(0);
	const [totalTasks, setTotalTasks] = useState(0);
	const [totalUsers, setTotalUsers] = useState(0);
	const [onlyPendingTasks, setOnlyPendingTasks] = useState(false);
	const [loading, setLoading] = useState(false);
	const [searchIndexBuilding, setSearchIndexBuilding] = useState(false);
	const [searchIndexProgress, setSearchIndexProgress] = useState(0);
	const [lastIndexingTime, setLastIndexingTime] = useState('');
	const [lastIndexingDuration, setLastIndexingDuration] = useState('a few seconds ago');
	// const searchData = useSelector(state => state.searchIndexReducer.documents);
	const dispatch = useDispatch();
	const [searchTarget, setSearchTarget] = useState('');

	const [isClicked, setIsClicked] = useState(false);
	const [btnClicked, setbtnClicked] = useState(false);
	const [isModalOpened, setIsModalOpened] = useState(false);

	const users = JSON.parse(localStorage.getItem('redwing_data_chopping'))?.users || [];

	let recentlyVisitedWorksheets =
		JSON.parse(localStorage.getItem('recently_visited_worksheets')) || [];
	let recentlyVisitedTasks = JSON.parse(localStorage.getItem('recently_visited_tasks')) || [];

	//Task Details popup
	const [openTaskDetailsModal, setOpenTaskDetailsModal] = useState(false);
	const [activeWorksheet, setActiveWorksheet] = useState('');
	const [selectedTask, setSelectedTask] = useState('');

	const navigate = useNavigate();
	const location = useLocation();
	const isSearchPage = location?.pathname?.includes('search');

	const fetchSearchData = async () => {
		const data = await getSearchData();

		if (!data || data.length === 0) getWorksheets();
		else {
			setDocuments(data);
		}
	};

	useEffect(() => {
		fetchSearchData();
	}, []);

	const fetchTasksForWorksheets = async () => {
		let tasksList = [],
			searchProgressIncrementFactor = 100.0 / worksheets.length;
		setSearchIndexBuilding(true);
		setSearchIndexProgress(0);

		await Promise.all(
			worksheets.map(async worksheet => {
				try {
					const response = await axios.get(
						`${process.env.REACT_APP_LARAVEL_API_URL}/task?worksheet_id=${worksheet?._id}`
					);
					tasksList = [...tasksList, ...response.data.tasks];
					setSearchIndexProgress(prev => prev + searchProgressIncrementFactor);
				} catch (error) {}
			})
		);
		setTasks(tasksList);

		setSearchIndexBuilding(false);
		setLastIndexingTime(new Date());
		setIsClicked(false);
	};

	useEffect(() => {
		fetchTasksForWorksheets();
	}, [worksheets]);

	useEffect(() => {
		let documents = [
			...tasks,
			...worksheets,
			// ...notifications,
			...users
		];
		if (worksheets?.length || tasks?.length) {
			setDocuments(documents);
			setValueToIndexedDB(documents);
		}
	}, [tasks, worksheets]);

	useEffect(() => {
		updateSearchIndex();
	}, [documents]);

	const updateSearchIndex = () => {
		let worksheetCount = 0,
			usersCount = 0,
			tasksCount = 0;

		let flexIndex = new Index({ tokenize: 'forward' });
		documents.forEach(document => {
			flexIndex.add(
				document._id,
				document.name + ' ' + document.title + ' ' + document?.description
			);
			// if (document?.name) flexIndex.add(document._id, document.name);
			// if (document?.description) flexIndex.add(document._id, document?.description);

			if (document?.access) worksheetCount++;
			else if (document?.name) usersCount++;
			else if (document?.estimate || document?.estimate === 0) tasksCount++;
		});
		setIndex(flexIndex);
		setTotalTasks(tasksCount);
		setTotalUsers(usersCount);
		setTotalWorksheets(worksheetCount);
	};

	// const getNotifications = () => {
	// 	axios
	// 		.get(`${process.env.REACT_APP_LARAVEL_API_URL}/notification`)
	// 		.then(res => {
	// 			setNotifications(res?.data?.data);
	// 		})
	// 		.catch(error => {});
	// };

	// const getComments = taskId => {
	// 	axios
	// 		.get(`${process.env.REACT_APP_LARAVEL_API_URL}/comment?todo_id=${taskId}`)
	// 		.then(res => {
	// 			let commentsList = {
	// 				...comments,
	// 				...res?.data?.comments
	// 			};
	// 			setComments(new Set(commentsList));
	// 		})
	// 		.catch(error => {});
	// };

	const getWorksheets = () => {
		axios
			.get(`${process.env.REACT_APP_LARAVEL_API_URL}/worksheet`)
			.then(res => {
				setWorksheets(res?.data?.worksheets);
			})
			.catch(error => {
				console.log(error);
			});
	};

	const search = searchText => {
		const escapedSearchText = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
		setLoading(true);
		setQuery(escapedSearchText);
		setSelectedResult(0);
		console.log(escapedSearchText, 'text');

		const results = index.search(escapedSearchText);
		let resultDocuments = [],
			resultTasks = {};

		let test = [];
		results.forEach(resultId => {
			const result = documents.find(doc => doc._id === resultId);

			if (
				(onlyPendingTasks &&
					!result?.completed &&
					result?.last_comment_type !== 'submission' &&
					result?.estimate >= 0) ||
				(!onlyPendingTasks && result?.estimate >= 0)
			) {
				if (!resultTasks[result.worksheet_id]) resultTasks[result.worksheet_id] = [];
				resultTasks[result.worksheet_id]?.push(result);
				test = [...test, result];
			}
			if (!onlyPendingTasks && !result?.estimate && result?.estimate !== 0) {
				resultDocuments = [result, ...resultDocuments];
			}
			if (onlyPendingTasks && !result?.estimate && result?.estimate !== 0) {
				resultDocuments = [result, ...resultDocuments];
			}
		});

		setMatchedDocuments(resultDocuments);
		setMatchedTasks(Object.entries(resultTasks));
		setLoading(false);
		setLastIndexingDuration(moment(lastIndexingTime).fromNow());
	};

	const contains = (arr, el) => {
		let hasElement = false;
		arr?.forEach(item => {
			if (item?._id === el?._id) hasElement = true;
		});
		return hasElement;
	};

	const handleNavigateToWorksheet = worksheetId => {
		const url = `/chopping-interface/${worksheetId}`;
		navigate(url);
	};

	const handleNavigation = (doc, ev = null) => {
		if (doc?.access && !contains(recentlyVisitedWorksheets, doc)) {
			cacheTaskInRecentlyVisitedList(doc);
		} else if (doc?.title && !doc?.access && !contains(recentlyVisitedTasks, doc)) {
			cacheTaskInRecentlyVisitedList(doc);
		}

		if (doc?.name) {
			navigate('/tasks/assigned/' + doc?._id);
		} else if (doc?.access) {
			console.log(doc?._id, activeWorksheetId, 'rrrrr');

			//* if the clicked worksheet is not open in background then navigate
			if (doc?._id !== activeWorksheetId) handleWorksheetChange(ev, doc?._id);

			setOpenSpotlightModal(false);
			// navigate('/chopping-interface/' + doc?._id);
		} else if (doc?.estimate || doc?.estimate === 0) {
			setOpenTaskDetailsModal(true);
			setActiveWorksheet(doc?.worksheet_id);
			setSelectedTask(doc);
		}
	};

	const handleResultsSelection = (e, doc) => {
		if (e.which === 38) {
			if (selectedResult === 0) {
				setSelectedId(ids[0]);
				return;
			}

			setSelectedResult(selectedResult - 1);
			setSelectedId(ids[selectedResult - 1]);
		}
		if (e.which === 40) {
			if (selectedId === ids[ids.length - 1]) {
				setSelectedResult(0);
				setSelectedId(ids[0]);
				return;
			}
			setSelectedResult(selectedResult + 1);
			setSelectedId(ids[selectedResult + 1]);
		} else if (e.which === 13) {
			let doc;
			for (let i = 0; i < matchedDocuments.length; i++) {
				if (matchedDocuments[i]._id === selectedId) {
					doc = matchedDocuments[i];
					break;
				}
			}
			if (doc) {
				handleNavigation(doc, e);
			} else {
				for (let i = 0; i < matchedTasks.length; i++) {
					for (let j = 0; j < matchedTasks[i][1].length; j++) {
						if (matchedTasks[i][1][j]._id === selectedId) {
							doc = matchedTasks[i][1][j];
							break;
						}
					}
				}
				handleNavigation(doc, e);
			}
		} else if (e.which === 27) {
			e.preventDefault();
			setOpenSpotlightModal(false);
		}
	};

	const handleCloseTaskDetailsModal = () => {
		setOpenTaskDetailsModal(false);
	};

	const getWorksheet = id => {
		return documents.find(doc => doc._id === id)?.title;
	};

	const rebuildSearchIndex = () => {
		// fetchTasksForWorksheets()
		setSearchIndexBuilding(true);
		setValueToIndexedDB([]);
		getWorksheets();

		setIsClicked(true);
		setbtnClicked(true);
	};

	const [selectedId, setSelectedId] = useState('');
	const [ids, setIds] = useState([]);

	const idSetter = () => {
		let idList = [];
		matchedDocuments?.forEach(item => {
			if (!Array.isArray(item)) idList.push(item._id);
		});
		matchedTasks?.forEach(tasks => {
			tasks[1]?.forEach(item => {
				idList.push(item._id);
			});
		});

		setIds(idList.filter(id => id !== undefined));
		setSelectedResult(0);

		window.innerWidth <= 600 ? setSelectedId('') : setSelectedId(idList[0]);
	};

	const inputRef = useRef(null);
	useEffect(() => {
		idSetter();
	}, [matchedDocuments, matchedTasks]);

	useEffect(() => {
		if (searchTarget) {
			search(searchTarget);
		}
	}, [onlyPendingTasks]);

	useEffect(() => {
		if (searchTarget?.length === 0) {
			displayRecentlyVisited();
		}
	}, [searchTarget, isModalOpened]);

	useEffect(() => {
		if (isModalOpened) {
			setTimeout(() => {
				inputRef.current?.focus();
				inputRef.current?.select();
			}, 100);
		}
	}, [isModalOpened]);

	const displayRecentlyVisited = () => {
		const worksheets = [...recentlyVisitedWorksheets];
		const tasksArr = [...recentlyVisitedTasks];
		const tasks = {
			tasks: tasksArr?.reverse()
		};
		worksheets?.reverse();
		setMatchedDocuments(worksheets);
		setMatchedTasks(Object.entries(tasks));
	};

	useEffect(() => {
		if (open) {
			setIsModalOpened(true);
		} else {
			setTimeout(() => {
				setIsModalOpened(false);
			}, 350);
		}
	}, [open]);

	const nodeContent = (
		<div
			className={`${
				!isMobile && open ? 'fade_in_bottom' : !isMobile ? 'fade_out_bottom' : ''
			} worksheet_body position-relative`}
			style={{
				flexDirection: 'column',
				width: !isSearchPage ? '50%' : '',
				minHeight: !isSearchPage ? 'fit-content' : '',
				position: 'relative'
			}}
		>
			<Backdrop className={`backdrop`} open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop>

			<div
				className={`search_container  ${origin === 'page' && 'pageStyle'}`}
				style={{ width: !isSearchPage ? '100%' : '' }}
			>
				<div className='search_box_container d-flex '>
					<input
						ref={inputRef}
						className='search_box   search-input'
						autoFocus
						value={query.replace(/\\([.*+?^${}()|[\]\\])/g, '$1')}
						onKeyDown={e => handleResultsSelection(e)}
						onChange={e => {
							search(e.target.value);
							setSearchTarget(e.target.value);
						}}
						placeholder={`Search for ${totalTasks} tasks, ${totalWorksheets} worksheets, ${totalUsers} Users, etc.`}
					/>

					{query && (
						<button
							onClick={() => {
								setQuery('');
								search('');
								setSearchTarget('');
								inputRef.current.focus();
							}}
							className='remove-query'
						>
							<CloseOutlineNew />
						</button>
					)}
				</div>

				{/* {searchIndexBuilding && (
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<div>Building search index, please wait:</div>
							<div className='circular-progress-container'>
								<div style={{ position: 'relative', display: 'inline-block' }}>
									<CircularProgress
										variant='determinate'
										value={searchIndexProgress}
										size={40}
										thickness={3}
									/>
									<Typography
										variant='body2'
										style={{
											position: 'absolute',
											top: '50%',
											left: '50%',
											transform: 'translate(-50%, -50%)',
											zIndex: 1
										}}
									>
										{searchIndexProgress.toFixed(0)}%
									</Typography>
								</div>
							</div>
						</div>
					)} */}

				{/* {!searchIndexBuilding && (
						<div className='result_text mt-4'>
							Searching : {totalWorksheets} Worksheets, {totalTasks} Tasks, {totalUsers} Users, Last
							indexing time: {lastIndexingDuration}
						</div>
					)} */}
				<div className='results-container'>
					<div className='search-results'>
						{searchTarget?.length === 0 && recentlyVisitedWorksheets?.length > 0 && (
							<div className='result_text text_header mb-4'>
								recent worksheets
								<span className='divider_line'></span>
							</div>
						)}
						{/**This is also used for showing recently visited */}

						<div style={{ gap: 16 }} className=' d-flex flex-column'>
							{matchedDocuments.map((doc, index) => {
								if (!doc || (Array.isArray(doc) && !doc?.length)) return null;

								return (
									<div
										className={`${
											selectedId === doc._id && 'selected_result'
										} result_item d-flex worksheet`}
										key={doc._id}
										onClick={ev => handleNavigation(doc, ev)}
									>
										{(doc?.access || doc?.estimate >= 0) && (
											<div className='d-flex align-items-center pr-2'>
												{doc?.access && <ListNew />}
												{doc?.estimate >= 0 && (
													<>
														{/* <TaskIcon item={doc} /> */}
														{/* <p className='m-0 p-0 ml-2' style={{ color: 'rgb(95, 185, 36)' }}>
															{doc?.estimate?.toFixed(2)}
														</p> */}
													</>
												)}
											</div>
										)}
										<div className='d-flex align-items-center' style={{ width: '100%' }}>
											{(doc?.access || doc?.title) && (
												<HighlightedText
													query={query}
													text={doc?.title}
													doc={doc}
													isTask={doc?.estimate >= 0}
												/>
											)}
											{!doc?.access && (
												<HighlightedText
													query={query}
													name={doc?.name}
													text={doc?.name}
													avatar={doc.avatar}
												/>
											)}
										</div>
									</div>
								);
							})}
						</div>
						{searchTarget?.length === 0 && recentlyVisitedTasks?.length > 0 && (
							<div className='result_text text_header mt-5 mb-4'>
								recent tasks <span className='divider_line'></span>
							</div>
						)}
						{matchedTasks.map((tasksList, index) => {
							const worksheetName = getWorksheet(tasksList[0]);
							const workSheetId = tasksList[0];
							return (
								<>
									{searchTarget?.length > 0 && (
										<div
											className='result_text text_header mt-4 mb-4 cursor-pointer'
											onClick={() => {
												handleNavigateToWorksheet(workSheetId);
											}}
										>
											{worksheetName}
											<span className='divider_line'></span>
										</div>
									)}
									<div className='d-flex flex-column' style={{ gap: '1.6rem' }}>
										{tasksList[1]?.map((doc, index) => {
											return (
												<div
													className={`${
														selectedId === doc._id && 'selected_result'
													}    result_item d-flex tsk-list`}
													key={doc._id}
													onClick={ev => handleNavigation(doc, ev)}
												>
													{(doc?.access || doc?.estimate >= 0) && (
														<div className='d-flex'>
															{doc?.estimate >= 0 && (
																<>
																	<TaskIcon item={doc} />
																	{/* <p className='m-0 p-0 ml-2' style={{ color: 'rgb(95, 185, 36)' }}>
																	{doc?.estimate?.toFixed(2)}
																</p> */}
																</>
															)}
														</div>
													)}
													<div className='d-flex align-items-center' style={{ flex: '1' }}>
														<HighlightedText
															query={query}
															text={doc?.title}
															doc={doc}
															isTask={doc?.estimate >= 0}
														/>
													</div>
												</div>
											);
										})}
									</div>
								</>
							);
						})}
						{matchedDocuments.length === 0 && matchedTasks.length === 0 && (
							<div className='result_text'>No results found</div>
						)}
					</div>
					<div
						className='d-flex justify-content-between align-items-md-center'
						style={{ fontSize: '1.1rem', paddingTop: '3.2rem' }}
					>
						<div>
							<Switch
								checked={onlyPendingTasks}
								onChange={() => {
									setOnlyPendingTasks(!onlyPendingTasks);
									// search('');
								}}
								size={'small'}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
							Only search pending tasks
						</div>
						{/* <div style={{ cursor: 'pointer' }} onClick={rebuildSearchIndex}>Rebuild search index</div> */}
						{isClicked ? (
							<>
								{searchIndexBuilding && (
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div>Building search index, please wait:</div>
										<div className='circular-progress-container'>
											<div style={{ position: 'relative', display: 'inline-block' }}>
												<CircularProgress
													variant='determinate'
													value={searchIndexProgress}
													size={40}
													thickness={3}
												/>
												<Typography
													variant='body2'
													style={{
														position: 'absolute',
														top: '50%',
														left: '50%',
														transform: 'translate(-50%, -50%)',
														zIndex: 1
													}}
												>
													{searchIndexProgress.toFixed(0)}%
												</Typography>
											</div>
										</div>
									</div>
								)}
							</>
						) : (
							<button
								style={{
									cursor: btnClicked ? 'not-allowed' : 'pointer',
									border: 'none',
									background: 'transparent',
									color: 'white',

									fontSize: '1.1rem',
									display: 'flex',
									alignItems: 'center',
									gap: '0.8rem'
								}}
								onClick={rebuildSearchIndex}
								disabled={btnClicked}
							>
								<IoMdRefresh size={15} style={{ rotate: '-90deg' }} />
								Rebuild search index
							</button>
						)}
					</div>
				</div>
			</div>
			{/* <Modal
					open={openTaskDetailsModal}
					onClose={handleCloseTaskDetailsModal}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
				>
					<Box className={`modal_container`}>
						<Box className={`modal_body`}>
							<div
								style={{
									display: 'flex',
									gap: 10,
									justifyContent: 'flex-end',
									cursor: 'pointer'
								}}
							>
								<div className={`fa_cross_icon`}>
									<FaTimes size={10} onClick={handleCloseTaskDetailsModal} />
								</div>
							</div>
							<TaskDetails
								activeWorksheet={activeWorksheet}
								item_id={selectedTask?._id}
								// updateTaskOnChoppingInterface={updateTaskFromTaskDetailsModal}
								// parentTitle={getParentTitle()}
								showDescBox={false}
								// hyperlinkedComment={hyperlinkedComment}
							/>
						</Box>
					</Box>
				</Modal> */}
			<TaskDetailsPopup
				open={openTaskDetailsModal}
				handleClose={handleCloseTaskDetailsModal}
				activeWorksheet={worksheets.find(item => item._id === activeWorksheet)}
				item_id={selectedTask?._id}
				openedTask={selectedTask}
			/>
		</div>
	);

	return isMobile && origin === 'page' ? (
		nodeContent
	) : (
		<Modal
			open={isModalOpened}
			onClose={() => {
				setOpenSpotlightModal(false);
			}}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'
			className='modal_root'
			disableEnforceFocus
		>
			{nodeContent}
		</Modal>
	);
};

const HighlightedText = ({ query, text = '', isTask = false, doc, avatar, name }) => {
	const regex = new RegExp(`(${query})`, 'gi');
	const highlightedText = text.replace(regex, '<mark>$1</mark>');

	const firstLetter = text.charAt(0).toUpperCase();

	return (
		<div className='d-flex align-items-md-center' style={{ flex: '1', gap: '1.6rem' }}>
			{name && (
				<div>
					{avatar ? (
						<Avatar src={avatar} sx={{ width: 24, height: 24, marginRight: '10px' }}></Avatar>
					) : (
						<Avatar sx={{ width: 24, height: 24, marginRight: '10px' }}>{firstLetter}</Avatar>
					)}
				</div>
			)}
			<p className='result_text'>{text}</p>
			<div
				className='d-flex align-items-center justify-content-end'
				style={{ height: 'fit-content' }}
			>
				{isTask && doc?.comments_count > 0 && (
					<>
						<CommentNew className='mr-2' />
						<p
							style={{
								fontSize: '10px',
								margin: 0
							}}
						>
							{doc?.comments_count}
						</p>
					</>
				)}
			</div>
		</div>
	);
};
